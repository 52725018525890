/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'kea'
import headerLogic from '../../kea/header'
import { NavLink } from '../general/util/Links'
import SearchMenu from '../general/search/SearchMenu'
import tmLogo from '../../assets/logos/tm-logo.svg'
import tmText from '../../assets/logos/tm-text-header.svg'
import './NavigationBar.scss'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { SearchIcon } from '../widgets/Icons'
import NavigationTop from './NavigationTop'
import { HEADER_STATE } from '../views/Header'
import track from 'react-tracking'
import DigimagHelp from '../../containers/DigimagHelp'

const matchesException = url => {
  return [
    '/autot/olemme-koonneet-talle-sivulle-tekniikan-maailman-koeajojutut-ja-koeajovideot/',
    '/autot/kaikki-tekniikan-maailman-172-kayttotestia-yhdessa-paikassa-nain-kay-kun-autolla-ajetaan-60-000-km/',
    '/nain-tekniikan-maailma-testaa-autoja/',
  ].includes(url)
}

@track({ gtmContext: ['NavigationBar'] })
@connect({
  props: [
    headerLogic, [
      'menus',
      'searchOpen',
    ],
  ],
  actions: [
    headerLogic, [
      'setSecondLevelNavStatus',
      'setSearchOpen',
    ]
  ]
})
class NavigationBar extends Component {
  static propTypes = {
    menus: PropTypes.array,
    headerState: PropTypes.string,
    pathname: PropTypes.string,
    isSecondLevelNavigationOpen: PropTypes.func,
    searchOpen: PropTypes.bool,
  }

  state = {
    showHelp: false
  }

  componentDidMount () {
    this.updateNavStatus(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.updateNavStatus(nextProps)
  }

  updateNavStatus (props) {
    const { menus, pathname } = props

    const secondLevelOpen = !pathname
      ? false
      : menus.some(menu => {
        return menu.children
          ? pathname.indexOf(menu.url) !== -1 || (menu.url === '/kategoria/autot/' && matchesException(pathname)) ||
        (menu.url === '/testit/' && pathname === '/testivoittajat/') ||
        (menu.url.indexOf('/lehti/') !== -1 && pathname.indexOf('/digilehdet/') !== -1) ||
        (menu.url.indexOf('/lehti/') !== -1 && pathname.indexOf('/teemalehdet/') !== -1) ||
        (menu.url === '/testit/' && pathname.indexOf('/testit') !== -1)
          : false
      })

    this.actions.setSecondLevelNavStatus(secondLevelOpen)
  }

  renderMainNavigation (pathname) {
    const { menus, headerState } = this.props

    const result = menus.map(origMenu => {
      let menu
      if (origMenu.url.indexOf('/lehti/') !== -1) {
        menu = { ...origMenu, url: '/digilehdet/', name: 'Digilehdet' }
      } else {
        menu = origMenu
      }

      const submenuShown = pathname
        ? pathname.indexOf(menu.url) !== -1 ||
        (menu.url === '/kategoria/autot/' && matchesException(pathname)) ||
        (menu.url === '/testit/' && pathname === '/testivoittajat/') ||
        (menu.url === '/testit/' && pathname.indexOf('/testit') !== -1) ||
        (menu.url.indexOf('/digilehdet/') !== -1 && pathname.indexOf('/digilehdet/') !== -1) ||
        (menu.url.indexOf('/digilehdet/') !== -1 && pathname.indexOf('/teemalehdet/') !== -1)
        : false

      const isHighlighted = submenuShown ||
        (menu.url.indexOf('/digilehdet/') !== -1 && pathname.indexOf('/lehti/') !== -1) ||
        (menu.url.indexOf('/digilehdet/') !== -1 && pathname.indexOf('/teemalehti/') !== -1)

      const navItemStyles = classnames('navigation-item', {
        open: isHighlighted,
      })
      const submenuContainerStyles = classnames('submenu-container', {
        open: submenuShown,
        collapsed: headerState !== HEADER_STATE.FULL,
      })
      const submenuStyles = classnames('navigation-submenu', {
        open: submenuShown,
      })

      return menu.children
        ? (
          <div styleName={`${navItemStyles}`} key={menu.url}>
            {this.taxOrPostTypeLink([menu.id, menu])}
            <div styleName={submenuContainerStyles}>
              <ul styleName={submenuStyles}>
                {menu.children.map(child => {
                  const isSubcategoryOpen = this.props.pathname ? this.props.pathname === child.url : false
                  const subcategoryStyles = classnames('navigation-item', {
                    open: isSubcategoryOpen,
                  })

                  return (
                    <li key={child.url} styleName={subcategoryStyles}>
                      {this.taxOrPostTypeLink([child.id, child])}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )
        : (
          <div styleName={`${navItemStyles}`} key={menu.url}>
            {this.taxOrPostTypeLink([menu.id, menu])}
          </div>
        )
    })

    return result
  }

  taxOrPostTypeLink ([slug, obj]) {
    // every links uses this, every link shouldn't
    // custom links don't work at all
    const path = obj.url || obj.url_base
    const target = {
      ...obj,
      link: path.indexOf('/') === 0 || path.indexOf('http') === 0 ? path : `/${path}`,
    }

    if (path.indexOf('/digilehden-lukuohje') === 0) {
      return (
        <button onClick={() => this.setState({ showHelp: true })} key={slug}>
          {obj.name}
        </button>
      )
    } else {
      return (
        <Fragment key={slug}>
          <NavLink to={target}>
            {obj.name}
          </NavLink>
        </Fragment>
      )
    }
  }

  toggleSearch = () => {
    this.openSearch(!this.props.searchOpen)
  }

  openSearch = (open = true) => {
    this.actions.setSearchOpen(open)
  }

  componentWillUnmount () {
    if (this.props.searchOpen) {
      this.openSearch(false)
    }
  }

  render () {
    const { headerState, menus, searchOpen, pathname } = this.props
    const { showHelp } = this.state

    const styles = ['desktop-navigation', headerState]

    return (
      <nav styleName={styles.join(' ')}>
        {showHelp ? <DigimagHelp close={() => this.setState({ showHelp: false })} /> : null}
        <SearchMenu
          onTransitionEnd={this.transitionEnd}
          transitionInStyle={{
            transition: 'opacity 0.3s ease-in',
            opacity: '1',
          }}
          transitionOutStyle={{
            transition: 'opacity 0.3s ease-out',
            opacity: '0',
          }}
          closeMenu={this.toggleSearch}
          open={searchOpen}
        />
        <div styleName="top-row-container">
          <div styleName="top-row">
            <NavigationTop />
          </div>
        </div>
        <div styleName="navigation-row-container">
          <div styleName="navigation-row">
            <h1 styleName="logo">
              <NavLink to={{ link: '/' }}>
                <img src={tmLogo} alt="" role="presentation"/>
                <img src={tmText} alt="" role="presentation"/>
                <span className="screen-reader-text">Tekniikan Maailma</span>
              </NavLink>
            </h1>
            <div styleName="main-nav">
              {menus.length ? this.renderMainNavigation(pathname) : null}
            </div>

            <div styleName="righthand-buttons">
              <div styleName="search">
                <button id="nav-bar-search-button" styleName="search-button" onClick={this.toggleSearch}>
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default withErrorBoundary(
  NavigationBar,
  ErrorPlaceholder(null)
)
