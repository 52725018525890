import React, { Component, Fragment } from 'react'
import WP from '../../lib/WP'
import ArticleListItem from '../general/article/ArticleListItem'
import './MostReadPaid.scss'
import PropTypes from 'prop-types'
import { IMAGE_SIZE } from '../../entities/ImageModel'
import track from 'react-tracking'
import shuffle from 'lodash/shuffle'

class MostReadPaid extends Component {
  _mounted = false
  state = {
    optimizeValue: 0
  }

  static propTypes = {
    exclude: PropTypes.array.isRequired, // article id's to exclude
    doneLoading: PropTypes.func,
  }

  selectedNumbers = new Set()
  pickFromArray(array, pickCount) {
    let result = []
    for (const num of array) {
      if (!this.selectedNumbers.has(num)) {
        this.selectedNumbers.add(num);
        result.push(num);
        if (result.length === pickCount) {
          break; // Stop once we've picked the required numbers
        }
      }
    }
    return result
  }

  async loadData (props) {
    const [mostReadArticles, mostOrderArticleIDs] = await Promise.all([WP.getMostReadPaid(), WP.getMostShoppingCartAction()])
    if (this._mounted) {
      this.setState({ exclude: props.exclude })
      let filteredMostOrderIDs = shuffle(mostOrderArticleIDs.filter((id) => !this.state.exclude.includes(id)))
      let filteredMostReadArticleIDs = shuffle(mostReadArticles.filter((article) => !this.state.exclude.includes(article.id)).map(x => x.id))


      let selectedOrderIDs = this.pickFromArray(filteredMostOrderIDs, 2)
      const selectedMostReadIDs = this.pickFromArray(filteredMostReadArticleIDs, selectedOrderIDs < 2 ? 4-selectedOrderIDs.length : 2)
      if (selectedOrderIDs.length + selectedMostReadIDs.length < 4) {
        // pick more from the first array
        const moreOrderIds = this.pickFromArray(filteredMostOrderIDs, 4 - (selectedOrderIDs.length + selectedMostReadIDs.length))
        selectedOrderIDs = [...selectedOrderIDs, ...moreOrderIds]
      }

      const filteredMostReadArticles = mostReadArticles.filter((article) => selectedMostReadIDs.includes(article.id))
      const filteredMostOrderActionArticles = filteredMostOrderIDs.length
        ? (await WP.getFromPostTypes(Object.keys(WP.postTypes), { include: selectedOrderIDs })).data
        : []
      this.setState({ filteredMostOrderActionArticles, filteredMostReadArticles })
    }
    this.props.doneLoading && this.props.doneLoading()
  }

  async componentDidMount () {
    this._mounted = true
    this.loadData(this.props)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  componentWillReceiveProps (props) {
    if (JSON.stringify(props) !== JSON.stringify(this.props)) {
      this.loadData(props)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState !== this.state
  }

  render () {
    const { filteredMostOrderActionArticles, filteredMostReadArticles } = this.state
    if (!filteredMostOrderActionArticles || !filteredMostReadArticles) {
      return null
    }

    return (
      <Fragment>
        <div styleName="wrapper">
          <div styleName="title-container">
            <div styleName="title-icon"></div>
            <h2 styleName="title-content">Suosittelemme sinulle</h2>
          </div>
          <div styleName="full-row">
            {filteredMostOrderActionArticles.map((article, idx) => <div key={idx} styleName="half-col">
              <ArticleListItem article={article} size="md" relatedArticleListLimit={0}
                sizes="(max-width: 768px) 100vw, 400px" imageSize={IMAGE_SIZE.MEDIUM_LARGE}/>
            </div>)}
            {filteredMostReadArticles.map((article, idx) => <div key={idx} styleName="half-col">
              <ArticleListItem article={article} size="md" relatedArticleListLimit={0}
                sizes="(max-width: 768px) 100vw, 400px" imageSize={IMAGE_SIZE.MEDIUM_LARGE}/>
            </div>)}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default track({ gtmContext: ['MostReadPaid'] })(MostReadPaid)
