import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink, Link } from '../general/util/Links'
import { connect } from 'kea'
import headerLogic from '../../kea/header'
import auth from '../../kea/auth'
import {
  CaretLeft,
  CaretRight,
  ArrowRightIcon,
  UserIconRed
} from '../widgets/Icons'
import track from 'react-tracking'
import WP from '../../lib/WP'

import './BurgerMenu.scss'

export default @track({ gtmContext: ['BurgerMenu'] })
@connect({
  actions: [
    headerLogic, [
      'setBurgerMenuOpen',
    ],
  ],
  props: [
    auth, [
      'username',
      'loggedIn',
      'accessLevel',
    ],
  ],
})
class BurgerMenu extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: props.open,
      submenu: -1
    }
  }

  static propTypes = {
    children: PropTypes.any,
    menus: PropTypes.array,
    open: PropTypes.bool,
    parentLabel: PropTypes.string,
    collapseChildMenus: PropTypes.func,
    mainMenu: PropTypes.bool,
    onOpen: PropTypes.func,
    username: PropTypes.string,
    loggedIn: PropTypes.bool,
    accessLevel: PropTypes.bool,
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.open === false) {
      this.setState({
        open: false,
      })
    }
  }

  componentWillUnmount () {
    if (this.state.open && this.actions) {
      this.actions.setBurgerMenuOpen(false)
    }
  }

  toggleMenu = () => {
    this.actions.setBurgerMenuOpen(!this.state.open)
    this.setState({ open: !this.state.open, submenu: -1 })
  }

  collapseChildMenus = () => {
    this.toggleMenu()
    this.setState({ submenu: -1 })
  }

  renderMainMenu = () => {
    const menuStyles = classnames('menu', {
      open: this.state.open,
      closed: !this.state.open,
    })
    const mainMenu = this.renderMenu()
    return <Fragment>
      <div styleName={menuStyles}>
        <ul styleName="login-nav">
          {this.renderLoginMenuItem('/')}
        </ul>
        <ul styleName="main-nav">
          {mainMenu}
        </ul>
        <ul styleName="secondary-nav">
          {this.renderMenuItem('https://tekniikanmaailma.fi/toimituksen-yhteystiedot/', 'Toimitus')}
          {this.props.accessLevel > 2
            ? <li styleName="menu-item" key={'tilaa'}>
              <Link
                to={{ link: '/tekniikan-maailman-uusi-mobiilisovellus-on-nyt-ladattavissa-sovelluskaupoista-lue-kaikkia-sisaltoja-katevasti-sovelluksessa/' }}>Lataa
                sovellus</Link>
            </li>
            : <li styleName="menu-item" key={'tilaa'}>
              <NavLink to={{ link: '/tilaa/' }}
                onClick={(e) => {
                  let promoPosition = '-single'
                  if (window.location.pathname === '/') {
                    promoPosition = '-etusivu'
                  } else if (window.location.pathname.includes('/kategoria/') || window.location.pathname.includes('/avainsana/')) {
                    promoPosition = '-kategoria'
                  }
                  window.location.href = 'https://tilaus.tekniikanmaailma.fi/tilaus?promoID=OM_tilaa-painike&promoName=OM_TM&promoCreative=onlinetarjous&promoPosition=header' + promoPosition
                  e.preventDefault()
                  return false
                }}>Tilaa lehti</NavLink>
            </li>
          }
        </ul>
      </div>
      {this.renderSubMenu()}
    </Fragment>
  }

  showSubmenu (idx) {
    this.setState({ submenu: idx })
  }

  renderMenu = () => {
    const menuElem = this.props.menus.map((menu, idx) => {
      let menuOverride
      if (menu.url.indexOf('/lehti/') !== -1) {
        menuOverride = { ...menu, url: '/digilehdet/', name: 'Digilehdet' }
      } else {
        menuOverride = menu
      }

      return menuOverride.children
        ? (
          <li styleName="menu-item" key={menuOverride.url}>
            {this.taxOrPostTypeLink([menuOverride.id, menuOverride])}
            <div styleName="submenu-icon">
              <button onClick={() => this.showSubmenu(idx)}>
                <CaretRight/>
                <span className='screen-reader-text'>Näytä alakategoriat</span>
              </button>
            </div>
          </li>
        )
        : (
          <li styleName="menu-item" key={menuOverride.url}>
            {!this.props.mainMenu && (
              <div styleName="sub-category-arrow">
                <ArrowRightIcon/>
              </div>
            )}
            {this.taxOrPostTypeLink([menuOverride.id, menuOverride])}
          </li>
        )
    })
    return menuElem
  }

  renderSubMenu = () => {
    if (this.state.submenu < 0) {
      return null
    }

    const menu = this.props.menus[this.state.submenu]
    let menuOverride
    if (menu.url.indexOf('/lehti/') !== -1) {
      menuOverride = { ...menu, url: '/digilehdet/', name: 'Digilehdet' }
    } else {
      menuOverride = menu
    }
    const menuElem = menu.children.map((submenu, idx) => {
      let subMenuOverride
      if (submenu.url.indexOf('/lehti/') !== -1) {
        subMenuOverride = { ...submenu, url: '/digilehdet/', name: 'Digilehdet' }
      } else {
        subMenuOverride = submenu
      }
      return (
        <li styleName="menu-item" key={subMenuOverride.url}>
          <div styleName="sub-category-arrow">
            <ArrowRightIcon/>
          </div>
          {this.taxOrPostTypeLink([subMenuOverride.id, subMenuOverride])}
        </li>
      )
    })

    return (
      <div styleName="menu submenu open">
        <button styleName="back-button" onClick={() => this.setState({ submenu: -1 })}>
          <div styleName="icon"><CaretLeft/></div>
          <div styleName="text">{menuOverride.name}</div>
        </button>
        <ul styleName="submenu">
          {menuElem}
        </ul>
      </div>
    )
  }

  renderMenuItem = (url, label) => {
    return (
      <li styleName="menu-item" key={label}>
        <NavLink onClick={this.toggleMenuAndChildren} to={{ link: url }}>{label}</NavLink>
      </li>
    )
  }

  renderLoginMenuItem = (url) => {
    const { loggedIn } = this.props
    if (loggedIn) {
      return (
        <li styleName="menu-item login-button">
          <Link id="login-button-mobile" to={{ link: '/tili' }}><UserIconRed/> <span>Oma tili</span></Link>
        </li>
      )
    } else {
      return (
        <li styleName="menu-item login-button">
          <a href={WP.getLoginUrl()}>
            <span>Kirjaudu</span>
          </a>
        </li>
      )
    }
  }

  toggleMenuAndChildren = () => {
    this.setState({ submenu: -1, open: false })
    this.actions.setBurgerMenuOpen(false)
  }

  taxOrPostTypeLink ([slug, obj]) {
    // every links uses this, every link shouldn't
    // custom links don't work at all
    const path = obj.url || obj.url_base
    const target = {
      ...obj,
      link: path.indexOf('/') === 0 || path.indexOf('http') === 0 ? path : `/${path}`,
    }

    return (
      <Fragment key={slug}>
        <NavLink onClick={this.toggleMenuAndChildren} to={target}>
          {obj.name}
        </NavLink>
      </Fragment>
    )
  }

  render () {
    const burgerIconStyle = classnames('hamburger-icon', {
      open: this.state.open,
    })
    const menu = this.renderMainMenu()
    return (
      <Fragment>
        <div styleName={`${burgerIconStyle}`}>
          <button id="burger-menu-button" onClick={this.toggleMenu}>
            <div>
              <span/><span/><span/><span/>
              <span className='screen-reader-text'>Avaa/Sulje valikko</span>
            </div>
          </button>
        </div>
        <div styleName="menu-container">
          {menu}
        </div>
      </Fragment>
    )
  }
}
